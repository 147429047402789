<input type="file" (change)="onFileSelected($event)" #fileInput hidden>

<ng-container *ngIf="customTemplate; else defaultTemplate">
  <ng-container
    *ngTemplateOutlet="customTemplate; context: {$implicit: file, fileInput: fileInput, onFileSelected: onFileSelected.bind(this)}"></ng-container>
</ng-container>

<ng-template #defaultTemplate>
  <div class="mat-mdc-form-field-flex d-flex flex-row align-items-center gap-2">
    <button mat-mini-fab color="primary" (click)="fileInput.click()">
      <mat-icon>attach_file</mat-icon>
    </button>
    <mat-label>{{ file?.name || "Klicken, um Datei auszuwählen (max. 100 MB)" }} {{ file?.size | fileSize }}</mat-label>
  </div>
</ng-template>
