import {Injectable} from '@angular/core';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {AppointmentEntity, AppointmentEntityWithRecurrences} from "@app/shared/models/appointment";
import {first, Observable, of, tap, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {expandRecurrence} from "@app/shared/recurrence-utils";
import {Customer} from "@app/shared/models/customer";

@Injectable()
export class AppointmentEntityService extends EntityCollectionServiceBase<AppointmentEntity> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Appointments', serviceElementsFactory);
  }

  get(appointmentId?: string): Observable<AppointmentEntity | undefined> {
    if (!appointmentId) return of(undefined);

    return this.entityMap$.pipe(
      map(entities => entities[appointmentId]),
      first()
    );
  }

  findByCustomerId(id: string, params: { startDate: Date, endDate: Date }): Observable<AppointmentEntity[]> {
    const queryParams: Record<string, string> = {
      customerId: id,
      startDate: params.startDate.toISOString(),
      endDate: params.endDate.toISOString()
    };

    return this.getWithQuery(queryParams);
  }

  findByCustomerIdWithRecurrences(id: string, params: {
    endDate: Date;
    startDate: Date
  }): Observable<AppointmentEntityWithRecurrences[]> {
    return this.findByCustomerId(id, params).pipe(
      map(appointments => {
        return appointments.map((it: AppointmentEntity): AppointmentEntityWithRecurrences => {
          const recurrences = expandRecurrence(it);
          return {
            ...it,
            groupId: it.groupId || it.id,
            recurrences
          }
        });
      })
    )
  }

  updateCustomerSettings(customerId: string, customer: Customer) {
    return of(customerId).pipe(
      withLatestFrom(this.entities$),
      map(([customerId, appointments]) => {
        return appointments.filter(it => it.customerId === customerId);
      }),
      tap(appointments => {
        const updatedAppointments = appointments.map(it => {
          // this update is necessary to reflect changes on the frontend without reloading the appointments.
          // the appointments are simultanously being updated in the backend by the updateCustomer operation
          return {...it, reminderSMS: customer.reminderSMS, reminderEmail: customer.reminderEmail};
        });
        this.updateManyInCache(updatedAppointments);
      })
    )
  }
}
