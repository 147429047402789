import {createAction, props} from "@ngrx/store";
import {CalendarDisplaySettings, CognitoUserAttributes, UserSettings} from "../models/current-user";
import {BookingSettingsUpdateRequest, TenantPublicDetails} from "@app/shared/models/tenant-public-details";


export const login = createAction(
  '[LoginComponent] login',
  props<{ cognitoUserAttributes: CognitoUserAttributes }>()
)

export const logout = createAction(
  '[SideNavComponent] logout',
)

export const saveCalendarDisplaySettings = createAction(
  '[CalendarDisplaySettingsComponent] save CalendarDisplaySettings',
  props<{ calendarDisplaySettings: CalendarDisplaySettings }>()
)

export const updateBookingSettings = createAction(
  '[CategoryTableComponent] update BookingSettings',
  props<{ bookingSettings: BookingSettingsUpdateRequest }>()
)

export const userDetailsLoaded = createAction(
  '[LoginEffect] user details loaded',
  props<{ userSettings: UserSettings, tenantPublicDetails: TenantPublicDetails }>()
)

