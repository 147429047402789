import {Injectable} from '@angular/core';
import {FileResponse, FileUploadResponse} from "../models/file";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, QueryParams} from "@ngrx/data";
import {catchError, forkJoin, Observable, of, ReplaySubject, switchMap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FileEntityService extends EntityCollectionServiceBase<FileResponse> {

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private httpClient: HttpClient,
  ) {
    super('Files', serviceElementsFactory);
  }

  public readonly lastUploadedFile$ = new ReplaySubject<File>();

  upload(file: File, fileName: string, metadata: Record<string, string>) {

    this.lastUploadedFile$.next(file);

    const s3KeyPrefix = metadata['s3KeyPrefix'] ?? '';

    const tenantId = metadata['tenantId'];
    const customerId = metadata['customerId'];
    const appointmentId = metadata['appointmentId'];
    const employeeId = metadata['employeeId'];
    const attachedObjectId =
      employeeId ? `employeeId#${employeeId}`
        : tenantId ? `tenantId#${tenantId}`
          : customerId ? `customerId#${customerId}`
            : appointmentId ? `appointmentId#${appointmentId}`
              : undefined;
    const request: Partial<FileResponse> = {
      mimeType: file.type,
      sizeInBytes: file.size,
      s3Key: s3KeyPrefix + fileName,
      attachedObjectId: attachedObjectId,
      uploadedBy: metadata['uploadedBy']
    }

    return this.add(request, {isOptimistic: false}).pipe(
      switchMap(response => {
        return this.httpClient.put((response as any as FileUploadResponse).presignedUploadUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        }).pipe(
          map(() => response)
        );
      }),
    )
  }

  subscribeWithThumbnails(queryParams: QueryParams, filter?: string): Observable<FileResponse[]> {
    this.getWithQuery(queryParams).subscribe();
    return this.entities$.pipe(
      map(entities => {
        return entities.filter(entity => {
          console.log(filter, entity.attachedObjectId);
          return !filter || (entity.attachedObjectId?.includes(filter));
        });
      }),
      switchMap(entities =>
        forkJoin(
          entities.map(entity =>
            this.getImageByPresignedUrl(entity.presignedThumbnailUrl).pipe(
              map(thumbnail => ({
                ...entity,
                thumbnail
              }))
            )
          )
        )
      )
    );
  }

  getImageByPresignedUrl(presignedThumbnailUrl: string): Observable<string | null> {
    return this.httpClient.get(presignedThumbnailUrl, {responseType: 'blob'}).pipe(
      map((blob) => URL.createObjectURL(blob)),
      catchError(() => of(null)) // If there's no thumbnail, return null
    );
  }
}
