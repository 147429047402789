import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {login, logout, saveCalendarDisplaySettings, updateBookingSettings, userDetailsLoaded} from "./actions";
import {
  catchError,
  combineLatestWith,
  concatMap,
  exhaustMap,
  mergeMap,
  Observable,
  of,
  tap,
  throwError,
  withLatestFrom
} from "rxjs";
import {Router} from "@angular/router";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {CognitoUserAttributes, UserSettings, UserSettingsUpdateRequest} from "../models/current-user";
import {v4} from "uuid";
import {select, Store} from "@ngrx/store";
import {cognitoUserAttributesSelector, tenantPublicDetailsSelector} from "./selectors";
import {BookingSettingsUpdateRequest, TenantPublicDetails} from "@app/shared/models/tenant-public-details";

@Injectable()
export class SharedModuleEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private httpClient: HttpClient,
    private store: Store,
  ) {
  }

  saveCalendarDisplaySettingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveCalendarDisplaySettings),
      exhaustMap(({calendarDisplaySettings}) => {
        return this.saveUserSettings({
          employeeId: v4(),
          calendarDisplaySettings: calendarDisplaySettings
        });
      })
    ), {dispatch: false}
  );

  updateTenantPublicSettingsEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBookingSettings),
      exhaustMap(({bookingSettings}) => {
        return this.updateBookingSettings(bookingSettings);
      })
    ), {dispatch: false}
  );

  loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      concatMap((action) =>
        this.loadUserSettings(action.cognitoUserAttributes).pipe(
          combineLatestWith(
            this.loadTenantPublicDetails(action.cognitoUserAttributes["custom:tenantId"])
          )
        )
      ),
      map(
        ([userSettings, tenantPublicDetails]) => userDetailsLoaded(
          {userSettings, tenantPublicDetails})
      )
    )
  );

  logoutEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      tap((action) => {
        this.router.navigate(['/public/login']);
      })
    ), {dispatch: false}
  );

  private loadUserSettings(cognitoUserAttributes: CognitoUserAttributes): Observable<UserSettings> {
    return this.httpClient.get<UserSettings>(`user-settings/${cognitoUserAttributes?.["custom:employeeId"]}`).pipe(
      catchError(e => {
        console.error(e);
        return throwError(() => 'Beim Laden der Einstellungen ist ein Fehler aufgetreten.');
      })
    );
  }

  private saveUserSettings(request: UserSettingsUpdateRequest): Observable<UserSettings> {
    return of(request).pipe(
      withLatestFrom(
        this.store.pipe(select(cognitoUserAttributesSelector))
      ),
      mergeMap(([request, cognitoUserAttributes]) => {
        return this.httpClient.put<UserSettings>(`user-settings/${cognitoUserAttributes?.["custom:employeeId"]}`, request);
      }),
      catchError(e => {
        console.error(e);
        return throwError(() => 'Beim Speichern der Einstellungen ist ein Fehler aufgetreten.');
      })
    );
  }

  private updateBookingSettings(request: BookingSettingsUpdateRequest): Observable<TenantPublicDetails> {
    console.log(request);
    return of(request).pipe(
      withLatestFrom(
        this.store.pipe(select(tenantPublicDetailsSelector))
      ),
      mergeMap(([request, tenantPublicDetails]) => {
        return this.httpClient.put<TenantPublicDetails>(`tenants/${tenantPublicDetails.id}/booking-settings`, request);
      }),
      catchError(e => {
        console.error(e);
        return throwError(() => 'Beim Speichern der Einstellungen ist ein Fehler aufgetreten.');
      })
    );
  }


  private loadTenantPublicDetails(tenantId: string): Observable<TenantPublicDetails> {
    return this.httpClient.get<TenantPublicDetails>(`tenants/${tenantId}/details`).pipe(
      map(tenant => {
        return tenant;
      }),
      catchError((err: any, caught: Observable<TenantPublicDetails>) => {
        console.error(err);
        return throwError(() => 'Beim Laden des Tenants ist ein Fehler aufgetreten');
      })
    )
  }
}
