import {Customer} from "./customer";
import {Color, Employee} from "./employee";
import {AppointmentTemplate} from "@app/appointment-templates/appointment-template";
import {CalendarEvent} from "calendar-utils";

export enum AppointmentCreatedBy {
  OnlineBooking = 'ONLINE_BOOKING',
  Internal = 'INTERNAL',
}


// an AppointmentEntity is "flattened" by transforming its recurrence rule and exceptions into an array of FlattenedAppointments
export interface FlattenedAppointment {
  id: string,
  recurrence: RecurrenceVariation,
  title: string,
  employeeIds: string[],
  customerId?: string,
  start: Date,
  durationInMinutes: number,
  location?: string,
  workTimeFactor?: number,
  priceInCent?: number,
  notes?: string,
  includeInAnalytics: boolean,
  allDay: boolean,
  reminderSMS?: boolean
  reminderEmail?: boolean
  remindBy?: string;
  reminderSent?: string;
  groupId?: string,
  color?: Color | null,
  createdBy?: AppointmentCreatedBy;
  createdAt?: string;
}

export type RecurrenceVariation = {
  type: RecurringAppointmentType.SingleAppointment
} | {
  type: RecurringAppointmentType.StartOfSeries,
  recurrenceRule: string,
} | {
  type: RecurringAppointmentType.RegularRecurrence,
  recurrenceRule: string,
} | {
  type: RecurringAppointmentType.RecurrenceException,
  regularStart: string,
};

export enum RecurringAppointmentType {
  SingleAppointment = 'SINGLE_APPOINTMENT',
  StartOfSeries = 'START_OF_SERIES',
  RegularRecurrence = 'REGULAR_RECURRENCE',
  RecurrenceException = 'RECURRENCE_EXCEPTION',
}

export interface FlattenedAppointmentDetails extends FlattenedAppointment {
  customer?: Customer,
  employees: Employee[],
}

export interface AppointmentUpdateBase {
  title: string,
  customerId?: string,
  employeeIds: string[],
  start: Date,
  durationInMinutes: number,
  location?: string,
  workTimeFactor?: number,
  priceInCent?: number,
  notes?: string,
  includeInAnalytics: boolean,
  allDay: boolean,
  reminderSMS: boolean
  reminderEmail: boolean,
  groupId?: string,
  color?: Color | null,
}

export interface AppointmentUpdateRequest extends AppointmentUpdateBase {
  recurrenceRule?: string,
}

export interface AppointmentCreatePrefill {
  start: Date,
  employeeIds: string[],
}

export interface MultiSlotFormPrefill {
  template: AppointmentTemplate | undefined;
  start: Date;
  title: string | null,
  color: Color | null
}

export interface RecurrenceException {
  replacement?: AppointmentEntity,
  regularStart: string,
}

export interface RecurrenceExceptionUpdateRequest {
  replacement?: AppointmentUpdateBase,
}

export interface AppointmentEntity {
  id: string,
  groupId?: string,
  title: string,
  employeeIds?: string[],
  customerId?: string,
  start: string,
  durationInMinutes: number,
  recurrenceRule?: string,
  location?: string,
  priceInCent?: number,
  notes?: string,
  includeInAnalytics: boolean,
  allDay: boolean,
  reminderSMS?: boolean
  reminderEmail?: boolean,
  color?: Color | null,
  recurrenceExceptions?: Array<RecurrenceException>,
  createdBy?: AppointmentCreatedBy;
  createdAt: string
}

export interface AppointmentEntityWithRecurrences extends AppointmentEntity {
  recurrences: Array<FlattenedAppointment>,
}

export interface AppointmentSlot {
  slotTitle: string,
  startDateTime: Date,
  durationInMinutes: number,
  pauseBeforeSlotInMinutes: number,
}

export interface AppointmentSlotCreateRequest {
  slotTitle: string,
  startDateTime: Date,
  durationInMinutes: number,
}

export enum AppointmentActionTarget {
  SingleAppointment = 'SINGLE_APPOINTMENT',
  Series = 'SERIES',
  FutureAppointmentsOfSeries = 'FUTURE_APPOINTMENTS_OF_SERIES',
}

export interface AppointmentTargetRequest {
  target: AppointmentActionTarget,
  selectedDate?: Date
}

export type DragAndDropAppointmentUpdate = {
  event: CalendarEvent<FlattenedAppointmentDetails>,
  newStart: Date,
  newEnd: Date | undefined,
  currentEmployee?: Employee,
  newEmployee?: Employee,
};


export interface TemplateOption {
  id: string,
  templateName: string,
  categoryId: string,
  categoryName?: string,
  color?: Color;
  alias?: string,
}

export type TemplateGroup = { categoryName: string, templates: TemplateOption[] };
