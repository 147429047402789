import {Injectable} from "@angular/core";
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {CustomDataFieldEntity} from "@app/shared/models/custom-data-field";
import {map} from "rxjs/operators";

@Injectable()
export class CustomDataFieldEntityService extends EntityCollectionServiceBase<CustomDataFieldEntity> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('CustomDataFields', serviceElementsFactory);
    this.getAll().subscribe(); // load all custom data fields from backend, when service is first initialized
  }

  getForEntity(entityType: string) {
    return this.entities$.pipe(
      map(customDataFields => customDataFields.filter(
        it => it.entityType === entityType
      ))
    );
  }
}
