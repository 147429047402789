import {Employee} from "./employee";
import {TenantFeatureConfig} from "@app/tenant-management/models/tenant";

export interface CognitoUserAttributes {
  'preferred_username': string,
  'custom:employeeId': string,
  'custom:tenantId': string,
  'custom:isTenantAdmin': 'true' | 'false'
}

export const DEFAULT_CALENDAR_SETTINGS: CalendarDisplaySettings = {
  orderByEmployeeId: {},
  dayStartHour: 6,
  dayEndHour: 22,
  displayTimeLabelColumn: {
    desktopLeft: true,
    desktopRight: false,
    phoneLeft: false,
    phoneRight: true
  },
  hourSegmentsDayView: 4,
  hourSegmentsWeekView: 2,
  appointmentAttributeOrder: {
    service: 0,
    customer: 1,
  }
}

export type ItemOrder = { [id: string]: number };
export type CalendarDisplaySettings = {
  orderByEmployeeId?: ItemOrder
  appointmentAttributeOrder?: ItemOrder
  displayTimeLabelColumn?: {
    desktopLeft: boolean,
    desktopRight: boolean,
    phoneLeft: boolean,
    phoneRight: boolean,
  }
  dayStartHour?: number;
  dayEndHour?: number;
  hourSegmentsDayView: number;
  hourSegmentsWeekView: number;
}

export interface UserSettings {
  employeeId: string,
  calendarDisplaySettings: CalendarDisplaySettings,
  tenantFeatureConfig: TenantFeatureConfig,
  employee: Employee,
}

export interface UserSettingsUpdateRequest {
  employeeId: string,
  calendarDisplaySettings: CalendarDisplaySettings,
}
