import {createReducer, on} from '@ngrx/store';
import {CalendarDisplaySettings, CognitoUserAttributes, DEFAULT_CALENDAR_SETTINGS} from "../models/current-user";
import {login, logout, saveCalendarDisplaySettings, updateBookingSettings, userDetailsLoaded} from "./actions";
import {TenantFeatureConfig} from "@app/tenant-management/models/tenant";
import {Employee} from "../models/employee";
import {TenantPublicDetails} from "@app/shared/models/tenant-public-details";


export interface SharedModuleState {
  cognitoUserAttributes?: CognitoUserAttributes,
  calendarDisplaySettings: CalendarDisplaySettings,
  tenantFeatureConfig: TenantFeatureConfig,
  tenantPublicDetails: TenantPublicDetails,
  currentUser?: Employee
}


export const initialState: SharedModuleState = {
  cognitoUserAttributes: undefined,
  calendarDisplaySettings: DEFAULT_CALENDAR_SETTINGS,
  tenantFeatureConfig: {
    smsSending: false
  },
  tenantPublicDetails: {} as any,
  currentUser: undefined
};

// export const reducers: ActionReducerMap<SharedModuleState> = {};

export const sharedReducer = createReducer(
  initialState,
  on(login, (state, {cognitoUserAttributes}) => ({...state, cognitoUserAttributes})),
  on(logout, () => initialState),
  on(userDetailsLoaded, (state, {userSettings, tenantPublicDetails}) =>
    ({
      ...state,
      currentUser: userSettings.employee,
      calendarDisplaySettings: {
        dayStartHour: userSettings.calendarDisplaySettings.dayStartHour || DEFAULT_CALENDAR_SETTINGS.dayStartHour,
        dayEndHour: userSettings.calendarDisplaySettings.dayEndHour || DEFAULT_CALENDAR_SETTINGS.dayEndHour,
        hourSegmentsDayView: userSettings.calendarDisplaySettings.hourSegmentsDayView || DEFAULT_CALENDAR_SETTINGS.hourSegmentsDayView,
        hourSegmentsWeekView: userSettings.calendarDisplaySettings.hourSegmentsWeekView || DEFAULT_CALENDAR_SETTINGS.hourSegmentsWeekView,
        orderByEmployeeId: userSettings.calendarDisplaySettings.orderByEmployeeId || DEFAULT_CALENDAR_SETTINGS.orderByEmployeeId,
        displayTimeLabelColumn: userSettings.calendarDisplaySettings.displayTimeLabelColumn || DEFAULT_CALENDAR_SETTINGS.displayTimeLabelColumn,
        appointmentAttributeOrder: userSettings.calendarDisplaySettings.appointmentAttributeOrder || DEFAULT_CALENDAR_SETTINGS.appointmentAttributeOrder
      },
      tenantFeatureConfig: userSettings.tenantFeatureConfig,
      tenantPublicDetails
    })),
  on(saveCalendarDisplaySettings, (state, {calendarDisplaySettings}) => ({...state, calendarDisplaySettings})),
  on(updateBookingSettings, (state, {bookingSettings}) => {
    return ({
      ...state,
      tenantPublicDetails: {
        ...state.tenantPublicDetails,
        ...bookingSettings,
      }
    });
  }),
)
