import {Injectable} from '@angular/core';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {Employee, UserPermissions} from "../models/employee";
import {map} from "rxjs/operators";
import {combineLatestWith, first, Observable, of} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {getWeekDay} from "src/app/shared/work-schedule-utils";
import {select} from "@ngrx/store";
import {calendarDisplaySettingsSelector} from "@app/shared/store/selectors";

@Injectable()
export class EmployeeEntityService extends EntityCollectionServiceBase<Employee> {
  activeEmployees$ = this.entities$.pipe(
    map(employees => employees.filter(it => !it.deleted)),
    combineLatestWith(this.store.pipe(select(calendarDisplaySettingsSelector))),
    map(([employees, settings]) => {
      const order = settings.orderByEmployeeId ?? {};
      return employees.sort(
        (a, b) => (order[a.id] - order[b.id])
      );
    })
  );
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private httpClient: HttpClient,
  ) {
    super('Employees', serviceElementsFactory);
  }

  findEmployeesForDate(date: Date) {
    return this.activeEmployees$.pipe(
      map(employees => {
        const weekday = getWeekDay(date); // 0 - 6,  0 = Sunday
        return employees.filter(employee => employee.availabilityRules[weekday].length > 0)
      }),
    );
  }

  get(employeeId?: string) {
    if (!employeeId) return of(undefined);

    return this.entityMap$.pipe(
      map(entities => entities[employeeId]),
      first()
    );
  }

  getMany(employeeIds: string[]) {
    return this.entities$.pipe(
      map(entities => entities.filter(employee => employeeIds.includes(employee.id)))
    );
  }

  updatePermissions(employee: Employee, request: UserPermissions): Observable<Employee> {
    return this.httpClient.put<UserPermissions>(`user-permissions/${employee.id}`, request).pipe(
      map((permissions) => {
        const updatedEmployee: Employee = {
          ...employee, permissions
        }
        this.updateOneInCache(updatedEmployee);
        return updatedEmployee;
      })
    )
  }
}
