<h2 mat-dialog-title>{{ description }}</h2>

<mat-dialog-content>
  <mat-tab-group dynamicHeight mat-align-tabs="start">
    <mat-tab label="Kundendaten">
      <form class="mt-2" [formGroup]="customerForm">

        <app-busy-indicator [state]="customerServiceBusy$ | async"></app-busy-indicator>

        <div class="row">
          <mat-form-field class="col-12 col-md-3">
            <mat-label>Vorname</mat-label>
            <input matInput formControlName="firstName">
          </mat-form-field>
          <mat-form-field class="col-12 col-md-3">
            <mat-label>Nachname</mat-label>
            <input matInput formControlName="lastName">
          </mat-form-field>
          <mat-form-field class="col-12 col-md-3">
            <mat-label>Anrede</mat-label>
            <mat-select formControlName="salutation">
              <mat-option [value]="null"></mat-option>
              <mat-option [value]="'Herr'">Herr</mat-option>
              <mat-option [value]="'Frau'">Frau</mat-option>
              <mat-option [value]="'Divers'">Divers</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-12 col-md-3">
            <mat-label>Firmenname</mat-label>
            <input matInput formControlName="companyName">
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field class="col-10 col-md-6">
            <mat-label>Email-Adresse</mat-label>
            <mat-icon matSuffix>alternate_email</mat-icon>
            <input matInput formControlName="emailAddress" type="email">
          </mat-form-field>
          <div class="col-2 col-md-6 mb-3 d-flex flew-row align-items-center">
            <a *ngIf="customer?.emailAddress" href="mailto:{{customerForm.controls.emailAddress.getRawValue()}}"
               target="_blank" mat-stroked-button>Email senden</a>
          </div>
          <mat-form-field class="col-12 col-md-6">
            <mat-icon matSuffix>phone</mat-icon>
            <ngx-mat-intl-tel-input
              [preferredCountries]="['at', 'de', 'ch', 'it']"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              [inputPlaceholder]="'Telefonnummer'"
              [searchPlaceholder]="'Ländervorwahl'"
              name="tel"
              formControlName="phoneNumber"
            ></ngx-mat-intl-tel-input>
            <mat-error>Ungültige Telefonnummer.</mat-error>
          </mat-form-field>
          <mat-form-field class="col-12 col-md-6">
            <mat-label>Geburtstag</mat-label>
            <input matInput
                   formControlName="birthday" [matDatepicker]="picker"
                   (focus)="picker.open()">
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker startView="multi-year"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="row">
          <div class="mat-form-field-wrapper col-12 col-md-6">
            <mat-checkbox
              *ngIf="(tenantFeatureConfig$ | async)?.smsSending"
              formControlName="reminderSMS"
              color="primary">
              Der Kunde möchte per SMS an Termine erinnert werden
            </mat-checkbox>
          </div>
          <div class="mat-form-field-wrapper col-12 col-md-6">
            <mat-checkbox formControlName="reminderEmail"
                          color="primary">
              Der Kunde möchte per E-Mail an Termine erinnert werden
            </mat-checkbox>
          </div>
        </div>

        <div class="d-flex flex-column mt-2">
          <app-custom-data-fields-form
            [formGroup]="customerForm.controls.customDataFields"
            [customDataFields]="(customDataFields$ | async) ?? []"
            [data]="customer?.customDataFields ?? {}"
          ></app-custom-data-fields-form>
        </div>

        <div class="d-flex flex-column">
          <mat-form-field>
            <mat-label>Notizen</mat-label>
            <textarea matInput formControlName="notes" rows="5"></textarea>
          </mat-form-field>
        </div>

        <div formGroupName="postalAddress" class="d-flex flex-column">
          <mat-form-field>
            <mat-icon matSuffix>mail</mat-icon>
            <mat-label>Straße, Hausnummer</mat-label>
            <input matInput formControlName="addressLine1" name="street-address">
          </mat-form-field>

          <div class="d-flex flex-row flex-wrap gap-2 align-items-stretch">
            <mat-form-field class="flex-fill-evenly">
              <mat-label>Postleitzahl</mat-label>
              <input matInput formControlName="zip" name="postal-code">
            </mat-form-field>
            <mat-form-field class="flex-fill-evenly">
              <mat-label>Stadt</mat-label>
              <mat-icon matSuffix>location_city</mat-icon>
              <input matInput formControlName="city" name="city">
            </mat-form-field>
            <mat-form-field class="flex-fill-evenly">
              <mat-label>Land</mat-label>
              <input matInput formControlName="country" name="country">
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="customer?.id" style="opacity: 0.5" (click)="copyToClipboard(customer?.id)"
             class="mb-2">
          <small>Kunden-ID: {{ customer?.id }}</small>
        </div>
        <mat-error
          *ngIf="customerForm.invalid && customerForm.touched && customerForm.hasError('missingRequiredField')">
          Mindestens eines der folgenden Felder ist erforderlich: Vorname, Nachname, Telefon, Email, Firmenname oder
          Notizen.
        </mat-error>
      </form>
    </mat-tab>
    <mat-tab label="Termine">
      <ng-template matTabContent>
        <app-appointment-list *ngIf="customer" [customer]="customer"></app-appointment-list>
      </ng-template>
    </mat-tab>
    <mat-tab label="Dateien" *ngIf="(tenantFeatureConfig$ | async)?.fileUpload && customer?.id; let customerId">
      <ng-template matTabContent>
        <app-file-management *ngIf="currentUser$ | async; let currentUser"
                             [currentUser]="currentUser"
                             [customerId]="customerId"
        ></app-file-management>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions class="mt-2 button-bar gap-2">
  <button mat-stroked-button color="primary" type="button" (click)="this.closeDialog.emit();" class="flex-fill-evenly">
    Abbrechen
  </button>
  <button mat-flat-button color="primary" type="button" (click)="onSubmit()" class="flex-fill-evenly"
          [disabled]="!customerForm.valid ||(customerServiceBusy$ | async)?.busy || !!customer?.deleted">
    Speichern
  </button>
  <button *ngIf="this.customer && !this.customer.deleted" mat-stroked-button color="warn" type="button"
          class="flex-fill-evenly"
          (click)="this.deleteCustomer.emit(this.customer.id)">
    Löschen
  </button>
  <button mat-stroked-button color="primary" type="button" (click)="openPrintPage()" class="flex-fill-evenly">
    Druckvorschau
  </button>
</mat-dialog-actions>
